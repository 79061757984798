#carousel {
    margin: auto;
    padding: 10px 0px;
    width: 90%;
    margin-top: 60px;
}

#carousel img {
    height: 500px;
    width: 100%;
}

.slick-next:before, .slick-prev:before{
    color: black;
}

#carousel h3 {
    margin: 0px 5px;
}

#carousel p {
    font-family: inherit;
    text-align: center;
    margin: 5px;
}

@media only screen and (max-width: 767px) {
    #carousel {
      width: 100%;
  }
  
}
