label,
input {
  display: block;
  height: 30px;
  font-size: 18px;
}

input,
#textarea {
  margin-bottom: 20px;
  border-radius: 5px;
  border: 5px solid rgb(199, 198, 198);
  font-family: 'Nixie One', cursive;
  background-color: aliceblue;
  transition: 0.3s;
  -webkit-transition: background-color 0.3s ease-in-out;
  -moz-transition: background-color 0.3s ease-in-out;
  -o-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;

  -webkit-transition: box-shadow 0.3s ease-in-out;
  -moz-transition: box-shadow 0.3s ease-in-out;
  -o-transition: box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out;
}

#sendBtn {
  -webkit-transition: background-color 0.3s ease-in-out;
  -moz-transition: background-color 0.3s ease-in-out;
  -o-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
  font-weight: bolder;
  -webkit-transition: box-shadow 0.3s ease-in-out;
  -moz-transition: box-shadow 0.3s ease-in-out;
  -o-transition: box-shadow 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out;
  transition: 0.3s;
  background-color: dodgerblue;
}
input:hover,
#textarea:hover,
#sendBtn:hover {
  box-shadow: 0 12px 16px 0 rgba(230, 230, 230, 0.24),
    0 17px 50px 0 rgba(255, 255, 255, 0.19);
}
#alertMessage {
  background-color: aquamarine;
  border: 1px solid green;
  border-radius: 10px;
  margin-bottom: 10px;
}
#errorMessage p {
  padding: 5px;
  margin: 0;
  text-align: center;
}

#errorMessage {
  background-color: lightcoral;
  border: 1px solid red;
  border-radius: 10px;
  margin-bottom: 10px;
}
#alertMessage p {
  padding: 5px;
  margin: 0;
  text-align: center;
}

form {
  /* width: 400px; */
  margin: auto;
}



form * {
  width: 100%;
  /* margin: auto; */
}

.row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.row .column {
  margin: 0px 20px;
  min-width: 500px;
}

h2 {
  color: rgb(143, 204, 255);
  letter-spacing: 0.5ch;
}
p {
  
  font-size: 26px;
}

.column:nth-of-type(1) p {
  color: rgb(200, 230, 255);
  margin: 20px 0px;
}

.column:nth-of-type(1) h2 {
  margin: 0;
}

p a {
  text-decoration: none;
  color: inherit;
}
p a:hover {
  color: rgb(138, 138, 138);
  transition: 0.5s;
}
svg {
  color: azure;
}
.weekend-hour {
  margin-left: 25px;
}
textarea {
  height: 200px;
  font-size: 18px;
}

#form-container {
  z-index: 1;
}

input[type="submit"] {
  width: 25%;
  height: 50px;
  border: none;

}

input[type="submit"]:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
  transition: 0.3s;
  cursor: pointer;
  background-color: rgb(196, 228, 255);
}

.form-input:focus,
#textarea:focus {
  border: 5px solid rgb(196, 228, 255);
}
@media only screen and (max-width: 767px) {
  .row {
    /* margin: 0px 20px; */
    display: block;
  }
  .row .column {
    min-width: 0;
  }
  #form-container{
    position: relative;
  }

  .weekend-hour {
    margin-left: 0;
  }

  form * {
    width: -webkit-fill-available;
    margin: auto;
  }
  input {
    margin-bottom: 30px;
  }
  input[type="submit"] {
    background-color: dodgerblue;
    color: #000;
    width: 50%;
    
  }
  #textarea {
    height: 100px;
  }
  p {
    font-size: 1.2em;
  }
  #contact .container h1 {
    margin: 45px 0px;
  }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1024px) and (orientation:portrait) {
  .row {
    display: block;
  }

}
