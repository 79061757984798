.navbar {
  overflow: hidden;
  visibility: hidden;
  position: absolute;
  width: 100%;
  padding: 10px 0px;
  display: flex;
  margin: 0;
  list-style: none;
  justify-content: center;
  z-index: 1;
  background: rgb(214, 214, 214);
  /* transition: visibility 0.3s linear, opacity 0.3s linear; */
}

#nav.sticky {
  top: 0;
  position: fixed;
  width: 100%;

}

#nav.stick.scroll-on {
  visibility: visible;
  opacity: 1;
}

.navbar li:hover {
  cursor: pointer;
  color: rgb(158, 158, 158);
}

ul li {
  text-decoration-color: #0000;
  transition: 0.5s;
  color: #000;
  font-weight: 600;
  font-size: 32px;
  margin: 0 25px;
}

.scroll-on {
  visibility: visible;
}
@media (hover: none) {
  .navbar li:hover{
    color: inherit;
  }
}
@media only screen and (max-width: 767px) {
  ul li {
    font-size: 18px;
    background: rgb(214, 214, 214);
  }
  .navbar {
    justify-content: space-between;
  }
}
