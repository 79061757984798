html, body {
    overflow-x: hidden;
  }
  body {
    /* position: relative; */
    font-family: 'Nixie One', cursive;
    
  }






.container h1 {
    font-size: 48px;
    text-align: center;
    position: relative;
    margin: 10px 0px;
}





#about {
    /* background-image: url("../../public/gokul-images/DSC_7892.JPG"); */
    height: auto;
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    
}



#contact {
    /* background-image: url("../../public/gokul-images/building.jpeg"); */

    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    height: 100vh;
}

#contact .container {
    margin: auto;
}

#contact::before {    
    content: "";
    /* background-image: url('../../public/gokul-images/city.jpeg'); */
    background-image: linear-gradient(rgb(14, 14, 14),rgba(51, 51, 51, 0.5)) , url('https://wallpaperaccess.com/full/3124512.jpg');
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

#home {
    /* background-image: url("../../public/gokul-images/building.jpeg"); */
    height: 100vh;
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    display: flex;
    /* padding-top: 5%; */
}

#home::before {    
    content: "";
    background-image: url('../../public/gokul-images/building.jpeg');
    background-size: cover;
    position: absolute;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.6;
}
#home .container {
    text-align: center;
    position: relative;
    margin: auto;

}
#logo {
    height: 300px;
    width: 300px;
}



@media only screen and (max-width: 767px) {

    #contact {
        height: auto;
    }
    

    #home {
        display: flex;
    }
    #home::before{
        background-size: 100% 100%;
        background-attachment: scroll;
    }
    #home .navbar {
        display: block;
    }

    #home .container {
        margin: auto;
    }
    .row .column {
        min-width: 0;
        margin: 0;
    }
    #logo {
        max-height: 200px;
        width: 200px;
        
    }
  }