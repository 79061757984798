#gallery  p {
    text-align: center;
    font-size: 26px;
}

#gallery img {
    border-radius: 15px;
}
.cup-container img {
    width: auto;
    margin: 0 2px;
    height: 400px;
}
.bag-container div {
    align-items: center;
    display: flex;
    justify-content: space-around;
    text-align: center;
}

.bag-container h2 {
    color: black;
    letter-spacing: normal;
}
#gallery {
    margin: 0 60px;
}

@media only screen and (max-width: 767px) {
    #gallery {
        margin: 0;
    }
    .bag-container h2 {
        font-size: 12px;
    }
  }